<template>
    <div class="app-main__inner">
        <div class="container  mb-3">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6 md:-mt-20">
                    <div class="mx-auto">
                        <img src="./../../assets/images/illus/error.svg" class="img-fluid" alt="error notice">
                        <div class="text-xl text-center link-theme-color font-bold md:-mt-8 text-gray-900">
                            {{ $t('notFoundText') }}
                        </div>
                        <div class="flex justify-center mt-5">
                            <router-link :to="{ name: 'regulardash' }" class="btn hover:text-white btn-custom btn-slider-b btn-wide btn-lg px-5">
                                <span>{{ $t('backHome') }}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name : 'notfound',
        components : {
        },
        data () {
            return {
                
            }
        },
        mounted () {

        },
        methods : {
        
        }
    }
</script>

<style lang="scss" scoped>

</style>